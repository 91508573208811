<template>
  <BaseCard
    :footer-visible="false"
    :caption-visible="false"
  >
    <template #title>
      <span class="h3 font-weight-bold mb-0 text-uppercase">Informe límites financieros</span>
    </template>
    <FinancialLimitReportsTable />
  </BaseCard>
</template>

<script>
import FinancialLimitReportsTable from '@/components/financial-limit-reports/table/FinancialLimitReportsTable.vue'
import BaseCard from '@/components/ui/card/BaseCard.vue'

export default {
  components: { 
    BaseCard, 
    FinancialLimitReportsTable
  },
}
</script>

<style scoped>
</style>
